import store from '@/store/index'
import {apiBaseUrl,sourc2} from '@/apiUrl/index.js'
import {setlocal,getlocal} from '@/utils/stroage'
import { Loading } from 'element-ui'
var loadinginstace
export default {
  //适应$aitool_开头标记为工具方法的使用
  install: function (Vue, options) {
    /**
   * 缓存的全局使用
   */
    Vue.prototype.$setlocal = setlocal;
    Vue.prototype.$getlocal = getlocal;
    // 时间格式化 yyyy-MM-dd hh:mm:ss
    Vue.prototype.$TimeFormat = function ({ fmt = 'yyyy/MM/dd', day = 0, date = null }) {
      // 如果今天时间没过早上【store.getters.dataDeviationTimeGetter】点就按昨天的日期
      if (new Date().getHours() < store.getters.dataDeviationTimeGetter) {
        day = day - 1
      }
      let addDate = (3600 * 24) * day * 1000 //一天的毫秒数
      let nowDate = !!date ? new Date(date).getTime() + addDate : new Date().getTime() + addDate
      let momentTime = new Date(nowDate);
      let o = {
        "M+": momentTime.getMonth() + 1,                 //月份
        "d+": momentTime.getDate(),                	//日
        "h+": momentTime.getHours(),                   //小时
        "m+": momentTime.getMinutes(),                 //分
        "s+": momentTime.getSeconds(),                 //秒
        "q+": Math.floor((momentTime.getMonth() + 3) / 3), //季度
        "S": momentTime.getMilliseconds()             //毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (momentTime.getFullYear() + "").substr(4 - RegExp.$1.length));
      }
      for (let k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
      }
      return fmt;
    }
    Vue.prototype.$gettimes = (num)=>{
        let h = parseInt(num / 60 / 60 % 24)
        let m = parseInt(num / 60 % 60)
        let s = parseInt(num % 60)
        //三元表达式 补零 如果小于10 则在前边进行补零 如果大于10 则不需要补零
        h = h < 10 ? '0' + h : h
        m = m < 10 ? '0' + m : m
        s = s < 10 ? '0' + s : s
        if(h==0){
          return `${m}:${s}`
        }else{
          return `${h}:${m}:${s}`
        }
        
    }

	Vue.prototype.$formatNumber =  (n)=> {
	  return n < 10 ? '0' + n : n;
	}
  Vue.prototype.$setlogin_show =  (e)=> {
	  store.commit('setlogin_show',e)
	}
	// 截取价钱前面
	Vue.prototype.$intercept_front = function(value){
		var num = (value.toString()).indexOf(".");
		if (num != -1) {
		num = value.toString().split('.');
		value = num[0]  //取点前的字符
		}
		return value;
	};
	// 截取价钱后面小数点
	Vue.prototype.$intercept_after = function(value){
	  var num = (value.toString()).indexOf(".");
	  if (num != -1) {
	   num = value.toString().split('.');
	   value ='.'+num[1]  //取点前的字符.
	   return value;
	  }
	}
	Vue.prototype.$msg = function(title,type){//通用简化版提示
    //1成功  2警告  3错误
    var state = type==2?'warning':(type==3?'error':'success')
		this.$message({
      message: title,
      showClose: true,
      type: state,
      offset:200
    });
	}
  Vue.prototype.$link = function(path,query,callback){//跳转页面
    callback&&callback();
    if (this.$route.path === path && !query) {
      this.$router.go(0)
    } else if (/^(http:)|(https:).+$/.test(path)) {
      window.location.href = path
    } else {
      console.log('push')
      this.$router.push({path,query})
    }
	}
  Vue.prototype.$links = function(path,query){//跳转新窗口页面
    var {href} = this.$router.resolve({path,query});
    window.open(href,'_blank');
	}
  Vue.prototype.$linkss = function(path){//跳转到外部链接
    window.open(path,'_blank');
	}
  Vue.prototype.showlogin = function(path){//显示登录弹窗
    store.commit('setlogin_show',true)
	}
  Vue.prototype.hidelogin = function(path){//隐藏登录弹窗
    store.commit('setlogin_show',false)
	}
	Vue.prototype.$totaltime=function(time){//倒计时
			var nowdate = new Date().getTime() //获取当前时间毫秒数
			time = time.replace(/-/g,'/') //ios不能识别日期格式中的 "-" ； .productExpiredTime是接口返回的名称
			var enddate = new Date(time).getTime() //处理好格式之后获取结束时间的毫秒数
			var totaltime = enddate - nowdate //获取时间差
			var h, m, s, d;
		
			function test(num) {
				if (num < 10) {
					num = "0" + num
				}
				return num
			}
			if (totaltime > 0) {
				d = Math.floor(totaltime / 1000 / 60 / 60 / 24) * 24
				h = Math.floor(totaltime / 1000 / 60 / 60 % 24)
				m = Math.floor(totaltime / 1000 / 60 % 60)
				s = Math.floor(totaltime / 1000 % 60)
				//获取时分秒
				h = d + h
				h = test(h)
				m = test(m)
				s = test(s)
				return `${h}:${m}:${s}` // 每个时间的显示格式
			} else {
				return `00 : 00 : 00`
		
			}
	}
  Vue.prototype.$showlog = function(options = {}){//显示加载中
    let { fullscreen, text } = options
    if (fullscreen === undefined) fullscreen = true
    if (text === undefined) text = '加载中...'
    loadinginstace = Loading.service({ fullscreen, text })
	}
  Vue.prototype.$hidelog = function(path,query){//隐藏加载中
    loadinginstace = loadinginstace.close()
	}
  Vue.prototype.$str = function (str) {
	  if(str==null || str==undefined || str == ''){return}
      str = JSON.parse(str);
      return str
    }
    Vue.prototype.$power = function (str) { //权限判断
      if(store.state.user.permissions.includes(str)) {
        return true
      } else {
        return false
      }
    } 
    Vue.prototype.$setobj = function (A,B) { //赋值
      for (var i in A) {
        if (B.hasOwnProperty(i)&&B[i]) {
            A[i] = B[i]
        }
      }
      return A
    }
    Vue.prototype.$before = function (file) { //验证只上传图片
      if(!(file.type == 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg')){
        this.$msg('只能上传peng,jpg,jpeg格式图片',3)
        return false;
      }
    }
    Vue.prototype.$before1 = function (file) { //验证只上传视频
      if(!(file.type == 'video/avi' || file.type === 'video/wmv' || file.type === 'video/ram'|| file.type === 'video/swf'|| file.type === 'video/mp4')){
        this.$msg('只能上传mp4,avi,wmv,ram格式视频',3)
        return false;
      }
    }
    Vue.prototype.$on_error = function (file) { //上传失败
      this.$msg('上传失败,请重试',3)
    }
    Vue.prototype.$back = function (file) { //返回上一页
      window.history.back();
    }
    Vue.prototype.$hide_phone = function (val) { //隐藏手机号码
      if((/^1[123456789]\d{9}$/.test(val))){
        var reg = /^(\d{3})\d{4}(\d{4})$/;
        return val.replace(reg, "$1****$2")
      }else{
        return val
      }
    }
    Vue.prototype.$copyText = function (val) { //复制
      var input = document.createElement("input");   // js创建一个input输入框
      input.value = val;  // 将需要复制的文本赋值到创建的input输入框中，this.ruleForm.url这个是我要复制的内容
      document.body.appendChild(input);    // 将输入框暂时创建到实例里面
      input.select();   // 选中输入框中的内容
      document.execCommand("Copy");   // 执行复制操作
      document.body.removeChild(input); // 最后删除实例中临时创建的input输入框，完成复制操作
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    }
    Vue.prototype.$downloadFile = function (val,name) { //下载
      let link = document.createElement('a');
      link.style.display = 'none';
      link.download = name;
      link.target = '_Blank';
      link.href = val;
      document.body.appendChild(link);
      link.click();
    }
    Vue.prototype.$count = function (val) { //计算数字
      // if(val*1>=10000){
      //   return (val/10000).toFixed(1) + '万'
      // }else{
        return val
      // }
    }
    Vue.prototype.$tran = function (e) { //会员等级
        switch (e) {
        case 0:
          return "普通用户";
        case 1:
          return "VIP会员";
        case 2:
          return "原创会员";
        case 3:
          return "银卡会员";
        case 4:
          return "金卡会员";
        case 5:
          return "白金会员";
        case 6:
          return "钛金会员";
        case 7:
          return "钻石会员";
        default:
        }
    },
    /**
      * 将秒转换为 分:秒
      * s int 秒数
      */
      Vue.prototype.$secondToMinutes = function(time){
        let h = parseInt(time / 60 / 60 % 24)
        h = h < 10 ? '0' + h : h
        let m = parseInt(time / 60 % 60)
        m = m < 10 ? '0' + m : m
        let s = parseInt(time % 60)
        s = s < 10 ? '0' + s : s
        // 作为返回值返回
        return h+':'+m+':'+s
    },
    Vue.prototype.$timeago = function(dateTimeStamp){   //dateTimeStamp是一个时间毫秒，注意时间戳是秒的形式，在这个毫秒的基础上除以1000，就是十位数的时间戳。13位数的都是时间毫秒。
	    var minute = 1000 * 60;      //把分，时，天，周，半个月，一个月用毫秒表示
	    var hour = minute * 60;
	    var day = hour * 24;
	    var week = day * 7;
	    var halfamonth = day * 15;
	    var month = day * 30;
	    var now = new Date().getTime();   //获取当前时间毫秒
	    var diffValue = now - dateTimeStamp;//时间差
	
	    if(diffValue < 0){
	    	return;
	    }
		var minC = diffValue/minute;  //计算时间差的分，时，天，周，月
		var hourC = diffValue/hour;
		var dayC = diffValue/day;
		var weekC = diffValue/week;
		var monthC = diffValue/month;
		var result = "";
		// if(monthC >= 1 && monthC < 4){
		// 	result = " " + parseInt(monthC) + "月前"
		// }else if(weekC >= 1 && weekC < 4){
		// 	result = " " + parseInt(weekC) + "周前"
		// }else if(dayC >= 1 && dayC < 7){
		// 	result = " " + parseInt(dayC) + "天前"
		// }else
		 if(hourC >= 1 && hourC < 24){
			result = " " + parseInt(hourC) + "小时前"
		}else if(minC >= 1 && minC < 60){
			result =" " + parseInt(minC) + "分钟前"
		}else if(diffValue >= 0 && diffValue <= minute){
	    	result = "刚刚"
	    }else if(dayC >= 1 && dayC < 7){
			result = " " + parseInt(dayC) + "天前"
		}
		else {
	    	var datetime = new Date();
			datetime.setTime(dateTimeStamp);
			var Nyear = datetime.getFullYear() + '';
			Nyear = Nyear.slice(-2);
			var Nmonth = datetime.getMonth() + 1 < 10 ? "0" + (datetime.getMonth() + 1) : datetime.getMonth() + 1;
			var Ndate = datetime.getDate() < 10 ? "0" + datetime.getDate() : datetime.getDate();
			var Nhour = datetime.getHours() < 10 ? "0" + datetime.getHours() : datetime.getHours();
			var Nminute = datetime.getMinutes() < 10 ? "0" + datetime.getMinutes() : datetime.getMinutes();
			var Nsecond = datetime.getSeconds() < 10 ? "0" + datetime.getSeconds() : datetime.getSeconds();
	    	result = Nyear + "-" + Nmonth + "-" + Ndate + " " + Nhour + ":" + Nminute
	    }
		return result;
	}

  }
  
  
}