import Vuex from 'vuex'
import Vue from 'vue'

import getters from '@/store/getters.js'

import user from '@/store/modules/user.js'
import {
  setlocal,
  getlocal,
} from '@/utils/stroage.js'

Vue.use(Vuex)

const store = new Vuex.Store({
	modules: {																																		
		user,
	},
	getters,
	state:{
		access_token:getlocal('access_token')||'',
		// 全局固定配置
		// tenantId:'f0712058a1a3489da904967c2aa05152',//励剑青春成长营
		// tenantId:'2a2b6d911fd3470f83bb80a74c4d8139',//般若灯书院
		// tenantId:'e68fe9efaa2d469ca93729a4ecc7d777', // 律师帮 - 线上
		// tenantId:'165c0d7bf44f4553b70b399b8e474917', // 我有才
		// tenantId:'6251bd275a6d4a71b25113e8ba42ec7d', // 东郊到家
		// tenantId:'7d210cd4643d41c0b23d49bda81580be', // 范琦
		// tenantId:'2a2b6d911fd3470f83bb80a74c4d8139', // 般若灯书院
		// tenantId:'8ab9f18b80c74400acbe88e5ff419a70', // 般若灯书院
		// tenantId:'2a2b6d911fd3470f83bb80a74c4d8139', // 觉之力
		// tenantId:'c5ed1edd020348f3b220bfc7eaddd8e2', // 习茗院
		// tenantId:'47210e4fd0544407a33aeb9100d99ab1', // IT部落
		// tenantId:'271d5662d57148a5812297c5cc50b7d3', // 慧眼宇宙
		// tenantId:'ff598fe184f94d3aad8d8b94353cb3d9', // 悦耳荟
		tenantId:'f0a6a6f454fc40aca4f9f7a6e4198ecd', // 奔牛
		// tenantId:'0034630c5c2a43749baad75f59bbe53f', // 宇宙大模型
		// tenantId:'82c84f687c634f748b8f058964db6dc9', // 秀学通																																																																														
		// tenantId:'afef5b6e7da84af98c992bb3fa83e360', // 勇闯家超能魔方
		// tenantId:'8ab9f18b80c74400acbe88e5ff419a70', // 随心灵蕴
		// tenantId:'d664a86e235b484d8f65f6f103f92d2c', // 乐之图
		// tenantId:'e355384062d446fbbc9fe94d023582a0', // 本地律师帮
		// tenantId:'3cfb027eb4634dfab764403a4cfdf01a', // 本地mingli
		// tenantId:'dffad1e34c804f45b9fb1c070665f953', // 必有我师
		// tenantId:'e6141ffeced8494d8ab463b43012abdf', // 大课堂		

	},
	mutations:{
		setaccess_token(state, val) {
            state.access_token = val;
            setlocal("access_token", val);
        },
	}
})

export default store
