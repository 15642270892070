import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/css/index.css'
import '@/assets/font/iconfont.css'
import '@/assets/css/animate.css'
import '@/assets/css/components.less'
import '@/assets/font-awesome/css/font-awesome.css'


import less from 'less'
Vue.use(less)

//全局点击防抖
const on = Vue.prototype.$on
Vue.prototype.$on = function(event, func) {
  let timer
  let flag = true
  let newFunc = func
  if (event === 'click') {
    newFunc = function() {
      if (flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function() {
        flag = true
      }, 500)
    }
  }
  on.call(this, event, newFunc)
}
router.afterEach((to, from, next) => {
    window.scrollTo(0, 0)
});

import videojs from 'video.js'

import 'video.js/dist/video-js.css'
Vue.prototype.$video = videojs
window.videojs = videojs
require('../node_modules/video.js/dist/lang/zh-CN')

import gap from '@/components/gap.vue'
import loadstyle from '@/components/loadstyle.vue'
import header from '@/components/header.vue'
import Homefooter from "@/components/footer.vue";
import nodata from '@/components/nodata.vue'

Vue.component('gap', gap)//高度间隔
Vue.component('loadstyle', loadstyle)//列表无数据
Vue.component('Header', header)//头部
Vue.component('Homefooter', Homefooter)//页脚
Vue.component('nodata', nodata)//暂无数据

Vue.config.productionTip = false
import ElementUI from 'element-ui'
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)

import preview from 'vue-photo-preview'
import 'vue-photo-preview/dist/skin.css'
Vue.use(preview); // 图片预览

import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload,{
  loading:require("@/static/images/placeholder.gif")
})

import smoothscroll from 'smoothscroll-polyfill'
window.__forceSmoothScrollPolyfill__ = true
smoothscroll.polyfill()

import methods from '@/utils/methods'
Vue.use(methods) 

import * as echarts from "echarts"
Vue.prototype.$echarts = echarts

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
