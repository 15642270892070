import {sourc,sourc2,getByTenantId,dictList,getUser} from "@/apiUrl/index.js"
import {
    setlocal,
    getlocal,
} from '@/utils/stroage.js'
export default {
    state: {
        userInfo:getlocal('userInfo') || {},
        token:getlocal('token') || '', //权限
        config:getlocal('config') || {}, //系统配置
        languageLabels:getlocal('languageLabels') || [],
        labels:getlocal('labels') || [],
        MonthIncomeArr:getlocal('MonthIncomeArr') || [],//月收入
        educationArr:getlocal('educationArr') || [],//学历
        login_show:false,//显示登录页面
    },
    mutations: {
        settoken(state,val){
            state.token = val
            setlocal('token',val)
        },
        setuserInfo(state,val){
            state.userInfo = val
            setlocal('userInfo',val)
        },
        setconfig (state, data) {
            state.config = data
            setlocal("config", data)
        },
        setlogin_show(state,val){
            state.login_show = val;
        },
        setlanguageLabels(state,val){
            state.languageLabels = val
            setlocal('languageLabels',val)
        },
        setlabels(state,val){
            state.labels = val
            setlocal('labels',val)
        },
        setMonthIncomeArr(state,val){
            state.MonthIncomeArr = val
            setlocal('MonthIncomeArr',val)
        },
        seteducationArr(state,val){
            state.educationArr = val
            setlocal('educationArr',val)
        },
    },
    actions: {
        getDict({ commit,state }) {
			dictList({type:'monthIncome'}).then(res => {
				commit('setMonthIncomeArr', res)
			})
			dictList({type:'education'}).then(res => {
				commit('seteducationArr', res)
			})
		},
        getUserInfo({commit,state},a){//获取个人信息
            getUser({id:state.userInfo.id}).then((res)=>{
                a&&a(res);
                if(res.code==200){
                    commit('setuserInfo', res.data)
                    commit('setlanguageLabels', res.data.labeList)
                    commit('setlabels', res.data.imageList)
                }else{
                    commit('setuserInfo', {})
                    commit('setlanguageLabels', [])
                    commit('setlabels', [])
                }
            })
        },
        getconfig({commit},a){//获取系统配置
            getByTenantId().then((res)=>{
                if(res.code==200){
                    var obj = res.data;
                    commit('setconfig', obj)
                }
                a&&a(res);
            })
        },
        // getvideoClass({commit},a){
        //     tagsList().then((res)=>{
        //         if(res.code==10000){
        //             var data = {
        //                 name:'全部',
        //                 id:'',
        //             }
        //             res.data.list.unshift(data);
        //             commit('setvideoclass', res.data.list)
        //         }
        //     })
        // },
        // gethistry({commit},a){
        //     historyList({currentPage:1,pageSize:'10'}).then((res)=>{
        //         if(res.code==10000){
        //             commit('sethistryList', res.data.result)
        //         }
        //     })
        // },
    }
}
