import Vue from 'vue'
import VueRouter from 'vue-router'
import {
    setlocal,
    getlocal,
} from '@/utils/stroage.js'
import store from '../store/index'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
} 
//meta参数值 jurisdiction:token权限验证 keepAlive是否暂存
const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/pages/home/index.vue'),
        meta: { title: '首页' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/screen',
        name: 'screen',
        component: () => import('@/pages/home/screen.vue'),
        meta: { title: '筛选' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/news_list',
        name: 'news_list',
        component: () => import('@/pages/home/news_list.vue'),
        meta: { title: '列表' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/commend_screen',
        name: 'commend_screen',
        component: () => import('@/pages/home/commend_screen.vue'),
        meta: { title: '推荐' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/video_detail',
        name: 'video_detail',
        component: () => import('@/pages/video/detail.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/video_detail1',
        name: 'video_detail1',
        component: () => import('@/pages/video/detail1.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/video_detail2',
        name: 'video_detail2',
        component: () => import('@/pages/video/detail2.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/teacher_detail',
        name: 'teacher_detail',
        component: () => import('@/pages/teacher/detail.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/activity_detail',
        name: 'activity_detail',
        component: () => import('@/pages/activity/detail.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/news_detail',
        name: 'news_detail',
        component: () => import('@/pages/news/detail.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/news_detail2',
        name: 'news_detail2',
        component: () => import('@/pages/news/detail2.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/v_play',
        name: 'v_play',
        component: () => import('@/pages/news/v_play.vue'),
        meta: { title: '详情' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/service',
        name: 'service',
        component: () => import('@/pages/agreement/service.vue'),
        meta: { title: '协议' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/message/system',
        name: 'system',
        component: () => import('@/pages/message/system.vue'),
        meta: { title: '系统消息' ,jurisdiction: true,keepAlive:true},
    },
    {
        path: '/forget_password',
        name: 'forget_password',
        component: () => import('@/pages/login/forget_password.vue'),
        meta: { title: '找回密码' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/modify_password',
        name: 'modify_password',
        component: () => import('@/pages/login/modify_password.vue'),
        meta: { title: '修改密码' ,jurisdiction: false,keepAlive:true},
    },
    {
        path: '/userCenter',
        name: 'userCenter',
        component: () => import('@/pages/userCenter/index.vue'),
        meta: { title: '个人中心' ,jurisdiction: true,keepAlive:true},
        children:[
            {
                path: '/profile',
                name: 'profile',
                component: () => import('@/pages/userCenter/profile.vue'),
                meta: { title: '个人资料' ,jurisdiction: false,keepAlive:false},
            },
            {
                path: '/view_record',
                name: 'view_record',
                component: () => import('@/pages/userCenter/view_record.vue'),
                meta: { title: '观看记录' ,jurisdiction: false,keepAlive:true},
            },
            {
                path: '/record',
                name: 'record',
                component: () => import('@/pages/userCenter/record.vue'),
                meta: { title: '购买记录' ,jurisdiction: false,keepAlive:true},
            },
            {
                path: '/collection',
                name: 'collection',
                component: () => import('@/pages/userCenter/collection.vue'),
                meta: { title: '课程收藏' ,jurisdiction: false,keepAlive:true},
            },
            {
                path: '/myComments',
                name: 'myComments',
                component: () => import('@/pages/userCenter/myComments.vue'),
                meta: { title: '课程评价' ,jurisdiction: false,keepAlive:true},
            },
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})
router.beforeEach((to, from, next) => {
    console.log('to',to)
    if (to.meta.jurisdiction) {  // 判断该路由是否需要登录权限
        if (getlocal('token')) {  // 通过 vuex state 获取当前的 token 是否存在
            next();
        } else {
            next({
                path: '/',
                query: { redirect: to.fullPath }  // 将跳转的路由 path 作为参数, 登录成功后跳转到该路由
            })
            store.commit('setlogin_show',true);
        }
    }else {
        next();
    }
})
var vue = new Vue()

export default router
