<template>
    <div>
        <div class="nodata">
            <div class="iconfont icon-wushuju" style="font-size: 70px;color: var(--color);line-height: 100px;padding-top: 25px;text-align: center;"></div>
            <div>
                {{ no_data }}
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:{
			no_data:{
				type:String,
				default:'暂无相关数据噢，再看看别的吧！'
			},
		},
        data() {
            return {
                
            }
        },
    }
</script>

<style lang="less" scoped>
.nodata {
    display: flex;
    flex-direction: column;
    align-items: center;
}
</style>