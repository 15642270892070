<template>
	<div>
		<div :style="{height:height+'px',backgroundColor: bg_color}"></div>
	</div>
</template>

<script>
	export default {
		name:"gap",
		props:{
			height:{
				type:[String,Number],
				default:0
			},
			bg_color:{
				type:String,
				default:''
			},
		},
		data() {
			return {
				
			};
		}
	}
</script>

<style lang="less">
	
</style>

