// import { msg,modal } from "@/config/system/index.js"
function msg (title, duration, mask,icon) {
	if (!title && title !== '') return
	uni.showToast({
		title,
		duration,
		mask,
		icon:icon||'none'
	})
}

//手机验证
const phoneValidator = (value) => {
	if (!(/^1[3456789]\d{9}$/.test(value))) {
		return true;
	} else {
		return false;
	}
}
//香港手机验证
const HKphoneValidator = (value) => {
	if (!(/^([5|6|9])\d{7}$/.test(value))) {
		return true;
	} else {
		return false;
	}
}
// 邮箱验证
const emailValidate = (value) => {
	if (!(/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value))) {
		  return true;
	}else {
		  return false;
	}
}
//身份证验证
const code_id = (value) => {
	if (!(/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value))) {
		msg('请输入正确的身份证号')
		return true;
	} else {
		return false;
	}
}
//验证不能为空
const verificationName = (value,text) => {
	if (!value) {
		msg(text?text:'真实姓名不能为空');
		return true;
	} else {
		return false;
	}
}
// 验证银行卡
 const validateBank = (bankno) => {
    if(bankno==''||bankno==undefined||bankno==null){
      msg('请输入银行卡号')
      return false
    }
    var lastNum = bankno.substr(bankno.length - 1, 1); //取出最后一位（与luhn进行比较）
    var first15Num = bankno.substr(0, bankno.length - 1); //前15或18位
    var newArr = new Array();
    for (var i = first15Num.length - 1; i > -1; i--) { //前15或18位倒序存进数组
    newArr.push(first15Num.substr(i, 1));
    }
    var arrJiShu = new Array(); //奇数位*2的积 <9
    var arrJiShu2 = new Array(); //奇数位*2的积 >9
    var arrOuShu = new Array(); //偶数位数组
    for (var j = 0; j < newArr.length; j++) {
    if ((j + 1) % 2 == 1) { //奇数位
        if (parseInt(newArr[j]) * 2 < 9) arrJiShu.push(parseInt(newArr[j]) * 2);
       else arrJiShu2.push(parseInt(newArr[j]) * 2);
    } else //偶数位
        arrOuShu.push(newArr[j]);
    }
    
    var jishu_child1 = new Array(); //奇数位*2 >9 的分割之后的数组个位数
    var jishu_child2 = new Array(); //奇数位*2 >9 的分割之后的数组十位数
    for (var h = 0; h < arrJiShu2.length; h++) {
        jishu_child1.push(parseInt(arrJiShu2[h]) % 10);
        jishu_child2.push(parseInt(arrJiShu2[h]) / 10);
    }
    
    var sumJiShu = 0; //奇数位*2 < 9 的数组之和
    var sumOuShu = 0; //偶数位数组之和
    var sumJiShuChild1 = 0; //奇数位*2 >9 的分割之后的数组个位数之和
    var sumJiShuChild2 = 0; //奇数位*2 >9 的分割之后的数组十位数之和
    var sumTotal = 0;
    for (var m = 0; m < arrJiShu.length; m++) {
        sumJiShu = sumJiShu + parseInt(arrJiShu[m]);
    }
    
    for (var n = 0; n < arrOuShu.length; n++) {
        sumOuShu = sumOuShu + parseInt(arrOuShu[n]);
    }
    
    for (var p = 0; p < jishu_child1.length; p++) {
        sumJiShuChild1 = sumJiShuChild1 + parseInt(jishu_child1[p]);
        sumJiShuChild2 = sumJiShuChild2 + parseInt(jishu_child2[p]);
    }
    //计算总和
    sumTotal = parseInt(sumJiShu) + parseInt(sumOuShu) + parseInt(sumJiShuChild1) + parseInt(sumJiShuChild2);
    
    //计算luhn值
    var k = parseInt(sumTotal) % 10 == 0 ? 10 : parseInt(sumTotal) % 10;
    var luhn = 10 - k;
    if (lastNum == luhn) {
    　return false;
    } else {
      msg('请输入正确的银行卡号')
      return true;
    }
  }
//资金密码
const checkPassword = (value, massage) => {
	// const regPasswordIsSame2a = /([A-Za-z0-9])\1{2,}/g
	// if (!/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/.test(value)) {
	// 	msg('必须是6-16位数字与字母的组合')
	// 	// callback(new Error('必须是6-16位数字与字母的组合'))
	// 	return true;
	// } else if (regPasswordIsSame2a.test(value)) {
	// 	// callback(new Error('不能出现连续三位相同的字符'))
	// 	msg('不能出现连续三位相同的字符');
	// 	return true;
	// } else {
	// 	return false;
	// }
	if (!value || value === '' || value === null || value === undefined) {
		msg(massage ? massage : '请输入资金密码')
		return true
	} else {
		return false;
	}
}
//检测相同
const confirmPassword = (value1, value2, massage) => {
	if (value1 !== value2) {
		msg(massage ? massage : '两次输入的登录密码不一致');
		return true;
	} else {
		return false;
	}
}
//注册账号
const validateUsername = (value) => {
  if (!(/^[0-9a-zA-Z]{6,18}$/.test(value))) {
		msg('用户名只能输入6~18位字符')
		return true;
  } else {
		return false;
  }
}
//注册密码
const validatePassword = (value) => {
  if (!(/^[0-9a-zA-Z]{2,18}$/.test(value))) {
		msg('密码只能输入2~18位字符')
		return true;
  }else {
		return false;
  }
}

//资金密码
const moneyValidatePassword = (value) => {
  if (!(/^[0-9a-zA-Z]{2,18}$/.test(value))) {
		msg('资金密码只能输入2~18位字符')
		return true;
  }else {
		return false;
  }
}

export {
	phoneValidator,//手机号
	HKphoneValidator,//手机号
	emailValidate,//邮箱验证
	verificationName,//银行卡名
	checkPassword,//资金
	confirmPassword,//重复
	validateUsername,//注册账号
	validatePassword, //注册密码
	moneyValidatePassword, //注册密码
	validateBank,//验证银行卡
	code_id //身份证
}
