<!-- 手机号码发验证码 -->
<template>
    <div>
        <div @click="bindforgetSendCode" class="cup" style="width:100%">
            {{ btnText }}
        </div>
    </div>
</template>

<script>
    import {phoneValidator,HKphoneValidator} from '@/utils/validator.js'
    import {sendCode,validate_code} from '@/apiUrl/index.js'
    export default {
        props:{
            mobile:{
                type:String,
                default:''
            },
            areaValue:{
                type:String,
                default:''
            },
            mark:{
                type:String,
                default:''
            },
            code:{
                type:[Number,String],
                default:null
            },
            nostr:{
                type:String,
                default:null,
            }
        },
        data() {
            return {
                btnText: '发送验证码',
                disabled: false,
            }
        },
        methods: {
            bindforgetSendCode() {
                if(this.disabled){
                    return
                }
                if(phoneValidator(this.mobile)){
                    this.$msg('请输入正确的手机号码',3)
                    return
                }
                this.getcode();
            },
            getcode(){
                sendCode({mobile:this.mobile,mark:this.mark}).then((res)=>{
                    if(res.code==200){
                        this.$msg('已发送验证码，请查收')
                        this.disabled = true;
                        this.btnText = '请稍候...'
                        setTimeout(() => {
                            this.doLoop(60)
                        }, 500)
                    }else{
                        this.$msg(res.message,3)
                    }
                })
            },
            doLoop(seconds) {
                //倒计时
                seconds = seconds ? seconds : 60
                this.btnText = seconds + 's后获取'
                let countdown = setInterval(() => {
                    if (seconds > 0) {
                        this.btnText = seconds + 's后获取'
                        --seconds
                    } else {
                        this.btnText = '获取验证码'
                        this.disabled = false
                        clearInterval(countdown)
                    }
                }, 1000)
            },
        },  
    }
</script>

<style lang="less" scoped>

</style>