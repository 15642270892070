<template>
	<div>
		<div class='no-data' v-if="isOK&&list.length==0">
		  <img :src="sourc2+'mini/default/car_none2.png'" />
		  <div>{{no_data}}</div>
		</div>
		<div class="h120"></div>
	</div>
</template>

<script>
	import {sourc,sourc2} from "@/apiUrl/index.js";
	export default {
		name:"loadStyle",
		props:{
			no_data:{
				type:String,
				default:'暂无相关数据噢~'
			},
			isOK:{
				type:Boolean,
				default:false
			},
			list:{
				type:Array,
				default:[]
			}
		},
		data() {
			return {
				sourc,sourc2
			};
		}
	}
</script>

<style lang="less">
.no-data{
  text-align: center;
  line-height: 3;
  padding-bottom: 100px;
}
.no-data img{
    width: 353px;
    display: block;
    margin: 0 auto;
    margin-top: 40px;
}
.no-data div{
    color: #999;
    margin-top: -60px;
}
.ismy_image{
	width: 50px;
	height: 50px;
}
.my_baseline{
	text-align: center;
	padding-bottom: 30px;
	color: #999;
}
</style>
