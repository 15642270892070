<template>
  <div id="app">
      <keep-alive>
        <router-view  v-if="$route.meta.keepAlive" v-wechat-title="$route.meta.title" :key="$route.fullPath"></router-view>
      </keep-alive>
      
      <router-view  v-if="!$route.meta.keepAlive" v-wechat-title="$route.meta.title" :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
import {mapGetters } from 'vuex'
// import {verifyToken,heartbeat} from "@/apiUrl/index.js";
export default {
  computed: {
    ...mapGetters(['token'])
  },
  data () {
    return {
      
    }
  },
  watch: {
   
  },
  created(){
      // 初始化后  vue2-editor.esm.js  multiple  
      // setTimeout(()=>{
      //   this.verify();//验证token
      // },1000)
      this.$store.dispatch("getconfig");//获取系统配置
      this.$store.dispatch("getDict");//数据字典
      // this.$store.dispatch("getvideoClass");//获取视频分类
      if(this.token){
          this.$store.dispatch("getUserInfo");
      }
      
  },
  methods:{
      // setheartbeat(){
      //     heartbeat().then((res)=>{

      //     })
      // },
      // verify(){
      //     verifyToken().then((res)=>{
      //         if(!res){
      //             this.$store.commit('settoken','');
      //             this.$store.commit('setuserInfo',{});
      //             this.$store.commit('sethistryList',[]);
      //         }else{
      //             this.$store.dispatch("getUserInfo");
      //         }
      //     })
      // }
  }
}
</script>
<style >
</style>
