const setlocal = function (key, value) {
  if(typeof value=='object'&&value!=null){
    value = JSON.stringify(value)
  }
  localStorage.setItem(key,value)
}
const getlocal = function (key) {
  var value = localStorage.getItem(key)
  if (typeof value =='string'&&(value.indexOf('{')>=0 || value.indexOf('[')>=0)) {
    return JSON.parse(value)
  } else {
    return value
  }
}
export {
  setlocal,
  getlocal
}