<template>
    <!-- <div style="position: sticky;top: 0px;z-index: 99999;"> -->
    <div>
        <div class="header_content" v-if="isOK">
            <div class="content">
                <div class="header_left spjz">
                    <div class="header_logo" @click="$link('/')" style="display: flex;align-items: center;">
                        <img :src="config.logoPhoto" alt="" :title="config.miniApps">
                        <span class="ml15 fw700">{{ config && config.slogan ? config.slogan : '' }}</span>
                    </div>
                    <div class="left_text this" @click="$link('/')">
                        首页
                    </div>
                    <!-- <div class="left_text">
                        学习
                    </div>
                    <div class="left_text">
                        问答
                    </div>
                    <div class="left_text">
                        课程
                    </div>
                    <div class="left_text">
                        学习会员
                    </div> -->
                </div>
                <div class="header_center spjz">
                    <div class="spjz">
                        <!-- @keyup.enter="$links('/screen')" -->
                        <input type="text" placeholder="" >
                        <div class="search_but spjz" >
                            <div class="iconfont icon-sousuo1"></div> 搜索
                        </div>
                    </div>
                </div>
                <div class="header_right spjz">
                    <div class="header_user spjz" @click="$links('/profile')" v-if="token!=''">
                        <div class="header_user1">
                            <img :src="userInfo.headPortraitUrl?userInfo.headPortraitUrl:'/static/images/personal/default.png'" alt="">
                            <div class="text_menu" style="top: 48px;">
                                <div class="menu_item">个人中心</div>
                                <div class="menu_item" @click.stop="$links('/modify_password')">修改密码</div>
                                <div class="menu_item" @click.stop="login_out">退出登录</div>
                            </div>
                        </div>
                    </div>
                    <div class="header_user spjz" @click="showlogin" v-else>
                        <div class="header_user1">
                            登录
                        </div>
                    </div>
                    <div class="right_text" @click="$link('/message/system')">
                        消息
                        <div class="text_menu">
                            <div class="menu_item" @click.stop="$link('/message/system')">系统消息</div>
                        </div>
                    </div>
                    <!-- <div class="right_text">
                        创作中心
                    </div>
                    <div class="right_text">
                        成就
                    </div>
                    <div class="right_text">
                        学习中心
                    </div>
                    <div class="right_text">
                        讲师后台
                    </div> -->
                </div>
            </div>
        </div>
        <div class="login-mask" v-if="login_show">
            <div class="login-content">
                <div class="login-top spczjz">
                    <img :src="config.logoPhoto" alt="" height="40px">
                    {{config.miniApps || ''}}
                    <div class="iconfont icon-cha2" @click="hidelogin"></div>
                </div>
                <div class="login-title">
                    <div class="login-title1">登录可享更多权益</div>
                    <div class="login-title2">
                        <span>{{config.slogan || ''}}</span>
                        
                    </div>
                </div>
                <div class="login-tab">
                    <!-- <div class="tab-item">微信登录</div> -->
                    <div class="tab-item" :class="num==1?'this':''" @click="isnum(1)">密码登录</div>
                    <div class="tab-item" :class="num==2?'this':''" @click="isnum(2)">免密登录</div>
                    <div class="tab-item" :class="num==3?'this':''" @click="isnum(3)">免费注册</div>
                </div>
                <div class="login-form" v-if="num==1">
                    <div class="login-form-item">
                        <input type="text" placeholder="手机号/邮箱/用户名" v-model="mobile" autocomplete="off">
                        <div class="iconfont icon-chacha" v-if="mobile" @click.stop="mobile=''"></div>
                    </div>
                    <div class="login-form-item">
                        <input :type="password_see?'password':'text'" placeholder="密码" v-model.trim="password" autocomplete="new-password">
                        <div class="iconfont icon-zhengyanicon" v-if="password_see" @click.stop="password_see=false"></div>
                        <div class="iconfont icon-icon-eye-close" v-else @click.stop="password_see=true"></div>
                    </div>
                    <div class="login-form-item-tips">
                        <span @click="$links('/forget_password')">忘记密码</span>
                    </div>
                    <div class="login-form-item login-form-but" @click="login_password">
                        登录
                    </div>
                    <div class="login-inform spczjz">
                        <el-checkbox v-model="checked" style="margin-right:5px;" text-color="var(--color)"></el-checkbox>
                        我已阅读并同意 <div class="login-agreement" @click="$links('service',{type:'user_protocol',title:'服务条款'})">服务条款</div> 和 <div class="login-agreement" @click="$links('service',{type:'private_protocol',title:'隐私协议'})">隐私协议</div>
                    </div>
                </div>
                <div class="login-form" v-if="num==2">
                    <div class="login-form-item">
                        <input type="text" placeholder="手机号" v-model="mobile" autocomplete="off">
                        <div class="iconfont icon-chacha" v-if="mobilelength" @click.stop="mobile=''"></div>
                    </div>
                    <div class="login-form-item">
                        <input type="text" placeholder="验证码" v-model.trim="code" autocomplete="new-password">
                        <div class="login-code-but">
                            <verification :mobile="mobile" :mark="'login'"></verification>
                        </div>
                    </div>
                    <div class="login-form-item-tips">
                        
                    </div>
                    <div class="login-form-item login-form-but" @click="login">
                        登录
                    </div>
                    <div class="login-inform spczjz">
                        <el-checkbox v-model="checked" style="margin-right:5px;" text-color="var(--color)"></el-checkbox>
                        我已阅读并同意 <div class="login-agreement" @click="$links('service',{type:'user_protocol',title:'服务条款'})">服务条款</div> 和 <div class="login-agreement" @click="$links('service',{type:'private_protocol',title:'隐私协议'})">隐私协议</div>
                    </div>
                </div>
                <div class="login-form" v-if="num==3">
                    <div class="login-form-item">
                        <input type="text" placeholder="手机号" v-model="mobile" autocomplete="off">
                        <div class="iconfont icon-chacha" v-if="mobilelength" @click.stop="mobile=''"></div>
                    </div>
                    <div class="login-form-item">
                        <input :type="password_see?'password':'text'" placeholder="密码" v-model.trim="password" autocomplete="new-password">
                        <div class="iconfont icon-zhengyanicon" v-if="password_see" @click.stop="password_see=false"></div>
                        <div class="iconfont icon-icon-eye-close" v-else @click.stop="password_see=true"></div>
                    </div>
                    <div class="login-form-item">
                        <input type="text" placeholder="验证码" v-model.trim="code" autocomplete="new-password">
                        <div class="login-code-but">
                            <verification :mobile="mobile" :mark="'register'"></verification>
                        </div>
                    </div>
                    <div class="login-form-item-tips">
                        <span @click="$links('/forget_password')">忘记密码</span>
                    </div>
                    <div class="login-form-item login-form-but" @click="register_">
                        注册
                    </div>
                    <div class="login-inform spczjz">
                        <el-checkbox v-model="checked" style="margin-right:5px;" text-color="var(--color)"></el-checkbox>
                        我已阅读并同意 <div class="login-agreement" @click="$links('service',{type:'user_protocol',title:'服务条款'})">服务条款</div> 和 <div class="login-agreement" @click="$links('service',{type:'private_protocol',title:'隐私协议'})">隐私协议</div>
                    </div>
                </div>
                <gap :height="20"></gap>
            </div>
        </div>
    </div>
</template>

<script>
import { sourc, sourc2,login2,register} from "@/apiUrl/index.js";
import verification from '@/components/verification.vue'
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters(["userInfo", "config","login_show","token"]),
    },
    components:{
        verification
    },
    props:{
        isOK:{
            type:Boolean,
            default:true,
        },
    },
    data() {
        return {
            sourc,
            sourc2,
            mobile:'',
            code:'',
            mobilelength:false,

            password:'',
            password_see:true,
            checked:false,
            num:1,
        };
    },
    watch:{
        mobile(a,b){
            a.length==0?this.mobilelength = false:this.mobilelength = true;
        }
    },
    created() {

    },
    methods: {
        login(){
            if(this.mobile.length==0 || this.code.length==0){
                this.$msg('请输入手机号或验证码!',3)
                return
            }
            if(!this.checked){
                this.$msg('请阅读并勾选服务条款和隐私协议!',3)
                return
            }
            login2({mobilePhone:this.mobile,code:this.code}).then((res)=>{
                if(res.code==200){
                    this.$msg('登录成功!')
                    this.$store.commit('settoken',res.data.token)
                    this.$store.commit('setuserInfo',res.data.memberInfo)
                    setTimeout(()=>{
                        this.$router.go(0)
                    },1000)
                }else{
                    this.$msg(res.message,3)
                }
            })
        },
        login_password(){
            if(this.mobile.length==0 || this.password.length==0){
                this.$msg('请输入手机号或密码!',3)
                return
            }
            if(!this.checked){
                this.$msg('请阅读并勾选服务条款和隐私协议!',3)
                return
            }
            login2({mobilePhone:this.mobile,password:this.password}).then((res)=>{
                if(res.code==200){
                    this.$msg('登录成功!')
                    this.$store.commit('settoken',res.data.token)
                    this.$store.commit('setuserInfo',res.data.memberInfo)
                    setTimeout(()=>{
                        this.$router.go(0)
                    },1000)
                }else{
                    this.$msg(res.message,3)
                }
            })
        },
        register_(){
            if(this.mobile.length==0 || this.password.length==0){
                this.$msg('请输入手机号或密码!',3)
                return
            }
            if(this.code.length==0){
                this.$msg('请输入验证码!',3)
                return
            }
            if(!this.checked){
                this.$msg('请阅读并勾选服务条款和隐私协议!',3)
                return
            }
            register({mobilePhone:this.mobile,password:this.password,code:this.code}).then((res)=>{
                if(res.code==200){
                    this.$msg('注册成功!')
                    this.$store.commit('settoken',res.data.token)
                    this.$store.commit('setuserInfo',res.data.memberInfo)
                    setTimeout(()=>{
                        this.$router.go(0)
                    },1000)
                }else{
                    this.$msg(res.message,3)
                }
            })
        },
        login_out(){
            this.$store.commit('settoken','');
            this.$store.commit('setuserInfo',{});
            this.$link('/')
        },
        isnum(num){
            this.num = num;
            this.mobile = this.password = this.code = '';
        }
    },
};
</script>

<style lang="less" scoped>
.header_content{
    height: 48px;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgb(0 0 0 / 5%);
    position: sticky;
    top: 0;
    z-index:12;
}
.content{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.header_logo{
    height: 46px;
    margin-right: 10px;
    cursor: pointer;
    img{
        height: 100%;
    }
}
.header_user{
    height: 48px;
    cursor: pointer;
    margin-right: 20px;
    line-height: 48px;
    text-align: center;
    width: 40px;
    color: #222;
    position: relative;
    box-sizing: border-box;
    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 99px;
    }
}
.header_user1{
    border-radius: 99px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: #e8e8ed;
}
.header_user:hover{
    .text_menu{
        display: block;
    }
}
.left_text{
    height: 48px;
    line-height: 44px;
    padding: 0 10px;
    cursor: pointer;
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    box-sizing: border-box;
    position: relative;
}
.left_text:hover{
    background-color: #f1f1f6;
    border-top: 2px solid #f1f1f6;
    border-bottom: 2px solid #f1f1f6;
}
.left_text.this{
    border-top: 2px solid var(--color) !important;
}
.header_center{
    width: 320px;
    height: 32px;
    input{
        width: 230px;
        height: 32px;
        border: 1px solid #e8e8ed;
        background: #f5f6f7;
        outline: 0;
        border-right: none !important;
        border-radius: 16px 0 0 16px;
        box-sizing: border-box;
        padding-left: 10px;
    }
    input:focus {
        border: 1px solid var(--color);
        border-right: none !important;
    }
}
.search_but{
    width: 88px;
    height: 100%;
    line-height: 32px;
    background-color: var(--color);
    color: #fff;
    border-radius: 0 16px 16px 0;
    cursor: pointer;
    .iconfont{
        margin-left: 14px;
        font-size: 20px;
    }
}
.right_text{
    height: 48px;
    line-height: 48px;
    padding: 0 10px;
    cursor: pointer;
    box-sizing: border-box;
    position: relative;
}
.right_text:hover,.left_text:hover{
    .text_menu{
        display: block;
    }
}
.text_menu{
    display: none;
    width: 120px;
    position: absolute;
    text-align: left;
    top: 46px;
    left: 50%;
    transform: translate(-50%,0);
    background-color: #fff;
    z-index: 9999;
    padding: 8px 0;
    box-shadow: 0 0 10px 2px rgb(0 0 0 / 6%);
    .menu_item{
        padding-left: 24px;
        height: 40px;
        line-height: 40px;
        box-sizing: border-box;
    }
    .menu_item:hover{
        background-color: #f0f0f5;
    }
}
.text_menu::before {
    content: '';
    position: absolute;
    width: 14px;
    height: 7px;
    background: url(../static/images/white.png) no-repeat center center;
    background-size: cover;
    left: 50%;
    top: -6px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

//登录
.login-mask{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba(0,0,0,.4);
    z-index: 999;
}
.login-content{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background-color: #fff;
    border-radius: 6px;
    width: 410px;
    overflow: hidden;
}
.login-top{
    height: 52px;
    font-size: 20px;
    font-weight: 700;
    font-family: cursive;
    background-color: #f7f7fc;
    position: relative;
    img{
        margin-right: 6px;
    }
    .icon-cha2{
        position: absolute;
        right: 20px;
        top: 14px;
        color: #aaa;
        cursor: pointer;
    }
}
.login-title1,.login-title2{
    height: 22px;
    display: block;
    text-align: center;
    font-size: 16px;
    font-family: PingFangSC-Semibold,PingFang SC;
    font-weight: 600;
    color: #222226;
    span{
        color: var(--color);
    }
}
.login-title1{
    margin: 24px auto 6px;
}
.login-tab{
    padding: 32px 24px 0;
    box-sizing: border-box;
    font-weight: 600;
    color: #999aaa;
    height: 32px;
    box-sizing: content-box;
    margin-bottom: 16px;
    justify-content: center;
    position: relative;
    display: flex;
}
.tab-item{
    width: 120px;
    display: inline-block;
    font-size: 16px;
    height: 22px;
    line-height: 16px;
    position: relative;
    cursor: pointer;
    user-select: none;
    text-align: center;
}
.tab-item.this{
    color: #222226;
}
.tab-item.this::after{
    content: "";
    display: block;
    width: 24px;
    height: 2px;
    background: #222226;
    position: absolute;
    bottom: -8px;
    left: 50%;
    transform: translateX(-50%);
}
.login-form{
    padding: 0px 24px 0;
    box-sizing: border-box;
    .login-form-item{
        width: 100%;
        height: 40px;
        line-height: 40px;
        background: #f5f6f7;
        border-radius: 4px 4px;
        padding: 0 16px;
        color: #222226;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        .iconfont{
            position: absolute;
            width: 20px;
            height: 20px;
            line-height: 20px;
            top: 12px;
            right: 16px;
            cursor: pointer;
            color: #aaa;
        }
        input{
            display: block;
            width: 100%;
            height: 100%;
            border: none;
            background: #f5f6f7;
            outline: none;
        }
        .login-code-but{
            position: absolute;
            right: 16px;
            top: 0px;
        }
    }
    .login-form-item+.login-form-item{
        margin-top: 16px;
    }
}
.login-form-item-tips{
    margin: 7px 0 24px;
    height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #222226;
    line-height: 16px;
    padding-left: 16px;
    padding-right: 16px;
    span{
        float: right;
        cursor: pointer;
    }
    span:hover{
        color: var(--color);
        text-decoration: underline;
    }
}
.login-form-but{
    background-color: var(--color) !important;
    color: #fff !important;
    line-height: 40px;
    border-radius: 999px !important;
    text-align: center;
    font-size: 16px !important;
    cursor: pointer;
}
.login-inform {
    height: 32px;
    line-height: 32px;
    margin: 8px 0 0;
    text-align: center;
    font-size: 12px;
    font-family: PingFangSC-Regular,PingFang SC;
    font-weight: 400;
    color: #222226;
}
.login-agreement{
    color: #222226;
    font-size: 12px;
    cursor: pointer;
    margin: 0 2px;
}
.login-agreement:hover{
    color: var(--color);
    text-decoration: underline;
}
</style>
